
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_enrollment = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_enrollment || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_enrollment = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_enrollment, ...{
    "enrollment": "Enrollment",
    "follow_these_steps": "Follow these steps to complete your enrollment:",
    "follow_these_steps_online": "Follow these steps to complete your enrollment when you're back online:",
    "upload_your_id": "Upload your ID",
    "verify_your_identity": "Verify your identity",
    "upload_unofficial_transcripts": "Upload your unofficial transcripts",
    "upload_unofficial_transcript": "Upload your unofficial transcript",
    "send_us_your_transcripts": "Request official transcripts for:",
    "upload_english_language_documents": "Upload English Language Proficiency documentation",
    "provide_mailing_address": "Confirm your mailing address",
    "in_review": "(In Review)",
    "learn_enrollment_requirements": "Learn more about <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"{enrollmentFaqLink}\" target=\"_blank\">enrollment requirements</a>.",
    "have_questions": "Questions? Contact <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"mailto:{brandEmail}\" target=\"_blank\">{brandEmail}</a> or schedule an <a class=\"text-white underline hover:text-yellow font-normal after:hidden\" href=\"https://calendly.com/d/d7n-s3n-fx4\" target=\"_blank\">appointment</a>.",
    "sign_enrollment_agreement": "Sign your Enrollment Agreement",
    "sign_tila_disclosure": "Sign your Payment Plan Disclosure",
    "complete_student_profile": "Complete your student profile",
    "show_completed_steps": "+ Show completed steps",
    "hide_completed_steps": "- Hide completed steps",
    "due_by": "Due by",
    "past_due": "Past Due Tasks",
    "complete_your": "Complete Your",
    "create_exam_signatures": "Create a digital signature for exam identity verification"
}
}
    